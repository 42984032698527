import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../../components/layout_en"
import {Container, Card, Row, Col, Badge} from "react-bootstrap"
import tree from "../../images/tree.svg"
import Seo from "../../components/seo"
import { GatsbyImage } from "gatsby-plugin-image";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination} from "swiper";



const Blog = ({data}) => (



        <Layout>

                      <Seo
                          titleTemplate={`Cash Management Blog | Trezorino`}
                          title="Discover the best cash flow resources for CEOs and CFOs"
                          description="News, analyses, files, interviews: every day, Trezorino offers you the best in the world of cash management"
                          image={tree}
                          lang="en"
                      />


                      <div className="header-box-suivi justify-content-center pb-5" style={{backgroundColor:"#F8F8F8"}}>
                          <Container>
                              <h1 className="pb-3">Cash flow and business management blog</h1>


                              <Row >
                                  {data.une.edges.map(une => (
                                      <Col md={7} key={une.node.id} className="une">
                                          <GatsbyImage   className="headerImage" key={une.node.frontmatter.headerImage?.id} image={une.node.frontmatter.headerImage?.childImageSharp?.gatsbyImageData} alt="text a définir"/>

                                          {/* <div style={{fontWeight: "600",fontSize:"14px", color:"#0D6EFD", paddingTop:"15px", paddingBottom:"15px"}}>{une.node.frontmatter.categorie}</div>*/}
                                          <div style={{paddingBottom:"15px", paddingTop:"15px"}}>
                                              <Badge pill bg="primary" >
                                                  {une.node.frontmatter.categorie}
                                              </Badge>
                                          </div>
                                          <Link to={`/en/blog/${une.node.slug}`} style={{fontSize:"24px", fontWeight:"500"}} className="stretched-link blogHeaderTextCard">{une.node.frontmatter.title}</Link>
                                      </Col>
                                  ))}

                                  <Col md={5} style={{paddingLeft:"20px"}}>
                                      <Row  className="" style={{ height:"10%"}}>
                                          <p style={{fontWeight: "500", fontSize:"28px"}}>More +</p>
                                      </Row>

                                      {data.pl.edges.map(plus => (
                                          <Row key={plus.node.id} style={{height:"28%", position:"relative", paddingBottom:"10px", paddingTop:"10px",marginTop:"2%"}} className="plus">

                                                  <Col md={4} >
                                                      <GatsbyImage  className="plusHeaderImage" key={plus.node.frontmatter.smallImage?.id} image={plus.node.frontmatter.smallImage?.childImageSharp.gatsbyImageData} alt="text a définir"/>
                                                  </Col>

                                              <Col md={8}>
                                                  {/* <div style={{fontSize:"14px", color:"#0D6EFD", fontWeight:"600"}}>{plus.node.frontmatter.categorie}</div>*/}
                                                  <div style={{paddingBottom:"10px"}}>
                                                      <Badge pill bg="primary" >
                                                          {plus.node.frontmatter.categorie}
                                                      </Badge>
                                                  </div>
                                                  <Link to={`/en/blog/${plus.node.slug}`} style={{fontSize:"16px", fontWeight:"500"}} className="stretched-link blogHeaderTextCard">{plus.node.frontmatter.title}</Link>
                                              </Col>
                                          </Row>
                                      ))}
                                  </Col>
                              </Row>

                          </Container>

                          {/*<Container className="mt-6">
                              <Row>
                                  <Carousel cols={5} rows={1} gap={10}>
                                      {data.liste.edges.map(article => (

                                          <Carousel.Item  key={article.node.id}>
                                              <Card style={{border:"none", height:"300px", width:"100%", borderRadius:"0px"}}>

                                                  {/*     <Card.Img variant="top" style={{border:"none", borderRadius:"0px"}} src={article.node.frontmatter.image.} /> */}

                          {/*}   <GatsbyImage  key={article.node.frontmatter.headerImage?.id} image={article.node.frontmatter.headerImage?.childImageSharp.gatsbyImageData} alt="text a définir"/>



                                                  <Card.Body style={{paddingBottom:"0px", paddingTop:"0px", paddingLeft:"0px"}}>
                                                      <div className="pt-3" style={{fontWeight: "600",fontSize:"14px", color:"#0D6EFD"}}>{article.node.frontmatter.categorie}</div>
                                                      <Card.Title className="blogHeaderTextCard"><Link to={`/blog/${article.node.slug}`} className="stretched-link blogHeaderTextCard">{article.node.frontmatter.title}</Link></Card.Title>

                                                  </Card.Body>
                                              </Card>
                                          </Carousel.Item>


                                      ))}
                                  </Carousel>
                              </Row>

                          </Container> */}
                          <Container className="mt-5">
                          <Swiper
                              slidesPerView={4}
                              spaceBetween={10}
                              slidesPerGroup={3}
                              loop={true}
                              loopFillGroupWithBlank={false}
                              pagination={false}
                              navigation={true}
                              modules={[Pagination, Navigation]}
                              className="mySwiper"
                          >
                              {data.liste.edges.map(article => (

                                  <SwiperSlide  key={article.node.id}>
                                      <Card className="blogCard" style={{ borderWidth:"0px", height:"300px", width:"100%", borderRadius:"10px", position: "relative", marginBottom: "10px"}}>

                                          {/*     <Card.Img variant="top" style={{border:"none", borderRadius:"0px"}} src={article.node.frontmatter.image.} /> */}
                                            <div style={{height:"60%",padding: "10px"}}>
                                                <GatsbyImage   className="plusHeaderImage" style={{borderRadius:"10px"}} key={article.node.frontmatter.headerImage?.id} image={article.node.frontmatter.headerImage?.childImageSharp?.gatsbyImageData} alt="text a définir"/>

                                            </div>



                                          <Card.Body style={{paddingBottom:"0px", paddingTop:"0px", paddingLeft:"10px", height:"40%"}}>
                                              {/* <div className="pt-3" style={{fontWeight: "500",fontSize:"14px", color:"#0D6EFD"}}>{article.node.frontmatter.categorie}</div>*/}
                                              <div style={{paddingBottom:"10px"}}>
                                                  <Badge pill bg="primary" >
                                                      {article.node.frontmatter.categorie}
                                                  </Badge>
                                              </div>

                                              <Card.Title className="blogHeaderTextCard"><Link to={`/en/blog/${article.node.slug}`} className="stretched-link blogHeaderTextCard">{article.node.frontmatter.title}</Link></Card.Title>

                                          </Card.Body>
                                      </Card>
                                  </SwiperSlide>
                              ))}
                          </Swiper>
                          </Container>
                      </div>
                  </Layout>
    )

export default Blog



export const data = graphql`
query blogListQueryen {
  liste: allMdx(
    filter: {frontmatter: {language: {eq: "en"}}}
    sort: {fields: frontmatter___date, order: DESC}
  ) {
    edges {
      node {
        id
        slug
        embeddedImagesRemote {
          childImageSharp {
            id
            gatsbyImageData(quality:100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        frontmatter {
          categorie
          date
          title
          une
          plus
          language
          headerImage {
            id
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          smallImage {
            id
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  une: allMdx(
    filter: {frontmatter: {une: {eq: true}, language: {eq: "en"}}}
    sort: {fields: frontmatter___date, order: DESC}
  ) {
    edges {
      node {
        id
        slug
        embeddedImagesRemote {
          childImageSharp {
            id
            gatsbyImageData(quality:100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        frontmatter {
          categorie
          date
          title
          une
          plus
          headerImage {
            id
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          smallImage {
            id
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
  pl: allMdx(
    filter: {frontmatter: {plus: {eq: true},language: {eq: "en"}}}
    sort: {fields: frontmatter___date, order: DESC}
  ) {
    edges {
      node {
        id
        slug
        embeddedImagesRemote {
          childImageSharp {
            id
            gatsbyImageData(quality:100, placeholder: NONE, layout: FULL_WIDTH)
          }
        }
        frontmatter {
          categorie
          date
          title
          une
          plus
          headerImage {
            id
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
          smallImage {
            id
            childImageSharp {
              gatsbyImageData(quality:100, placeholder: NONE, layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
}
`
